<template>
  <!--
    The view for the TriggerList, ContactList, HistoryList, DeviceListVisualisation and ProblemReport-components.
    The default view of each installation.
    Used to display all sorts of informations about the installation.
  -->
  <div class="installationDetail_Operation">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <div class="row">
        <div class="col-12 col-lg-12 col-xl-7">
          <div
            v-if="isNotOpen"
            class="card"
          >
            <TriggerList
              ref="triggerListOperationInInstallationDetail"
              :installation-id="installationId"
            />
          </div>
          <div
            v-if="!authenticationHasRole('zerolevel') && subtasksComponentVisible"
            class="card"
          >
            <JiraSubtasks
              :installation-id="installationId"
              :jira-subtasks="jiraSubtasks"
            />
          </div>
          <div
            v-if="!authenticationHasRole('zerolevel')"
            class="card"
          >
            <HistoryList
              ref="historyList"
              :installation-id="installationId"
              :note-id="noteId"
              @show="show"
            />
          </div>
        </div>

        <div class="col-12 col-lg-12 col-xl-5">
          <div class="card">
            <Portlet
              :title="$t('installationDetailOperationView.contacts')"
              icon="user-friends"
              portlet-body-class="m-portlet__body p-0 pb-3"
            >
              <template slot="buttons">
                <button
                  class="btn btn-sm btn-primary"
                  @click="openGroupEmail"
                >
                  <font-awesome-icon
                    class="mr-1"
                    icon="envelope"
                  />
                  Group Email
                </button>
              </template>
              <ContactList
                :installation-id="installationId"
                :installation-name="installationObject.name"
              />
            </Portlet>
          </div>

          <div
            v-if="authenticationHasRole('scope_staff')"
            class="card"
          >
            <Portlet
              :title="$t('onCallDuty')"
              icon="user-friends"
              portlet-body-class="m-portlet__body p-0 pb-3"
            >
              <ContactList
                :is-call-duty="true"
                :installation-id="installationId"
                :installation-name="installationObject.name"
              />
            </Portlet>
          </div>

          <div
            v-if="showDevicesCard"
            v-show="this.installationObject.installationType !== 'DestinationOS' || showDestOsValue"
            class="card"
          >
            <Portlet
              :title="$t('installationDetailOperationView.devices')"
              icon="hdd"
            >
              <DeviceListVisualisation
                :installation-id="installationId"
                @showDestOs="setShowDestOs"
              />
            </Portlet>
          </div>
        </div>
      </div>
    </template>

    <Sidebar
      v-if="showSidebar"
      :show-sidebar="showSidebar"
      @close="close"
    >
      <InstallationNote
        :installation-id="installationId"
        :caller="caller"
        @close="close"
      />
    </Sidebar>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import historyAdapter from '@/mixins/historyAdapter.js';

export default {
  name: "InstallationDetailOperation",
  components: {
    TriggerList: () => import('@/components/Problem/TriggerList_Operation.vue'),
    HistoryList: () => import('@/components/History/HistoryList_Operation.vue'),
    ContactList: () => import('@/components/Contact/ContactList.vue'),
    DeviceListVisualisation: () => import('@/components/Device/DeviceListVisualisation.vue'),
    JiraSubtasks: () => import('@/components/Jira/JiraSubtasks.vue'),
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    InstallationNote: () => import('@/components/Installation/InstallationNote.vue')
  },
  mixins: [
    authenticationMixin,
    historyAdapter
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    noteId: {
      type: String,
      required: false,
      default () {
        return null;
      }
    },
    skicon: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      installationObject: null,
      jiraSubtasks: null,
      loading: true,
      subtasksComponentVisible: false,
      showSidebar: false,
      caller: null,
      showDestOsValue: false
    }
  },
  metaInfo () {
    return {
      title: this.installationObject ? this.installationObject.name : this.$t('installationDetailOperationView.operation')
    }
  },
  computed: {
    isNotOpen: function () {
      if (!this.installationObject) {
        return false;
      }
      if (!this.installationObject.jiraStatus) {
        return false;
      }
      let stringToCheck = this.installationObject.jiraStatus.toLowerCase();
      if (stringToCheck.includes('open')) return false;
      return true;
    },
    showDevicesCard () {
      return this.installationObject.installationType != 'Altimeter Base Service' && this.installationObject.installationType != 'Core Service' 
      && this.installationObject.installationType != 'Emma'
      && this.installationObject.installationType != 'Competition' && this.installationObject.installationType != 'IT Service' 
      && this.installationObject.installationType != 'Skiline Online' && this.installationObject.installationType != 'Panocam';
    }
  },
  async created () {
    this.caller = this.skicon;
    if(this.caller) {
      this.show();
    }
    await this.getInstallation();
    this.getSubtasks();
  },
  methods: {
    setShowDestOs (val) {
      this.showDestOsValue = val
    },
    openGroupEmail () {
      this.$router.push({path: `/notify-group/${ this.installationId }`});
    },
    async getInstallation () {
      this.loading = true;
      await this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
        })
        .finally(() => {
          if(this.authenticationHasRole('scope_customer') && this.authenticationHasRole('zerolevel')) {
            var type = this.installationObject.installationType.trim().toLowerCase();
            var sessionTypes = [ 'skimovie', 'bikemove', 'photopoint', 'phototrap', 'photostart', 'speedcheck', 'time measurement', 'eventmovie' ];
            var statusTypes = [ 'terminal', 'mediastreamer'];
            if(sessionTypes.includes(type)) {
              this.$router.push({path: `/installation/${ this.installationId }/session`});
            }
            else if(statusTypes.includes(type)) {
               this.$router.push({path: `/installation/${ this.installationId }/terminal-status`});
            }
            else {
              this.$router.push({path: `/installation/${ this.installationId }/installation-contacts`});
            }
          }
        });
    },
    getSubtasks () {
      this.axios.get(`/Issue/GetOpenSubtasks?issueId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.jiraSubtasks = response.data;
          if (this.jiraSubtasks.length > 0)
          {
            this.subtasksComponentVisible = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
  }
}
</script>